
(function () {

    if($('.bloc-carte .overlay-map').length > 0 ) {

        th_overlay.addCallbackOpen(function (overlayId) {
            var exploded_id = overlayId.split('-');

            if(exploded_id.length == 3) {
                var start_overlay_id = exploded_id[0] + '-' + exploded_id[1] ;
                var num_overlay = exploded_id[2];

                if (exploded_id[0]) {
                    if (start_overlay_id == 'overlay-map') {
                        setTimeout(function () {

                            initSlider(document.querySelector('.th-slider-' + num_overlay));
                            th_maps.initSingleMap(document.querySelector('#map-bloccarto-' + num_overlay));

                        }, 250);
                    }
                }
            }
        });
    }


    function initSlider(slider) {
        var parent = slider.parentNode;
        console.log(slider,parent);
        while (!parent.className.match(/data-bottom/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var counterWrapper = parent.querySelector('.counter-wrapper');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: '1024',
            oninit: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }
                if (counterWrapper) {
                    thSliderTools.onInitCounter(slider, counterWrapper);
                }

            },
            onchange: function (slider) {
                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (counterWrapper) {
                    thSliderTools.onChangeCounter(slider, counterWrapper);
                }
            }
        });

    }


})();


function callbackMapBlockSlider(macarte) {
    macarte.zoomControl.setPosition('topleft');

    var markers = [];
    console.log($(macarte._container));
    $('.results .card', $(macarte._container).parents('.bloc')).each(function () {
        var id = this.getAttribute('data-id');

        var latlng = {lat: this.getAttribute('data-lat') * 1, lng: this.getAttribute('data-lng') * 1};
        if (latlng.lat != 0 && latlng.lng != 0) {
            var marker = th_maps.createMarker(macarte, latlng, 'default');

            macarte.on("click", function (e) {
                marker.setIcon(th_maps.markersIcons['default']);
            });


            // Lorsqu'on fait un hover sur une card, on change l'icône du marqueur
            $(this).on({
                mouseenter: function () {
                    marker.setIcon(th_maps.markersIcons['hover']);
                },
                mouseleave: function () {
                    marker.setIcon(th_maps.markersIcons['default']);
                }
            });

            th_maps.createInfoWindow(this.outerHTML, marker, 260);
            markers.push(marker);
        }
    });
    var group = new L.featureGroup(markers);
    macarte.fitBounds(group.getBounds());
    if (isTouchDevice()) {
        macarte.dragging.disable();
    }
}