function parseCardOffresEmploiClickOverlay() {
    $('.card-manche-emploi:not(.parsed)').on('click', function (e) {

        e.preventDefault();

        var elem = $(this);

        var overlay_id = 'overlay-emploi-info';

        th_overlay.open(overlay_id, true, true);


    }).addClass('parsed');

}

parseCardOffresEmploiClickOverlay();

