
function parseCardOffrePremiumClickOverlay() {
    $('.js-card-sit-overlay.card:not(.parsed)').add('.js-card-sit-overlay.btn').on('click', function (e) {

        e.preventDefault();

        var elem = $(this);

        var sit_url = elem.attr('href') + '?overlay';
        var overlay_id = 'overlay-sit';

        var date = moment($('.dtstart', elem).attr('datetime'), "YYYY-MM-DD").format("Do MMMM");
        var h_from = $($('.hours-js span', elem).get(0)).text();
        var h_to = $($('.hours-js span', elem).get(1)).text();

        $('#' + overlay_id + ' .js-content-overlay-sit').html('');
        $('#' + overlay_id + ' .loader').removeClass('hide');
        $('#' + overlay_id + ' .bottom-part').addClass('hide');

        $.get(sit_url, function (responseHTML) {
            $('#' + overlay_id + ' .loader').addClass('hide');
            $('#' + overlay_id + ' .js-content-overlay-sit').html(responseHTML);
            $('#' + overlay_id + ' .js-content-overlay-sit .time').html($('time',elem).html());
            $('#' + overlay_id + ' .bottom-part a').attr('href',elem.attr('href'));
            $('#' + overlay_id + ' .bottom-part').removeClass('hide');
            $('#' + overlay_id + ' .date-js').text(date);

            if(!h_from && !h_to) {
                $('#' + overlay_id + ' .dt-schedule').addClass('hide');
            } else if (h_from && !h_to) {
                $('#' + overlay_id + ' .hours-js-from').removeClass('hide');
                $('#' + overlay_id + ' .hour-js-from').text(h_from);
            } else if (h_from && h_to) {
                console.log('date from and to !!!!');
                $('#' + overlay_id + ' .hours-js-from-to').removeClass('hide');
                $('#' + overlay_id + ' .hour-js-from').text(h_from);
                $('#' + overlay_id + ' .hour-js-to').text(h_to);
            }
            var mapsContainer = document.querySelector('#' + overlay_id + ' .maps');
            if (mapsContainer) {
                th_maps.initSingleMap(mapsContainer);
            }
            setTimeout(function () {
                setSliderOverlaySit(overlay_id);
            }, 400);
        });

        th_overlay.open(overlay_id, true, true);


    }).addClass('parsed');


}
parseCardSitClickOverlay();








